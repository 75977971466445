<template>
  <base-section id="affiliates">
    <span v-intersect="handleIntersect"></span>
    <v-container>
      <base-section-heading title="The Team" />

      <v-container>
        <v-row>
          <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
            <base-avatar-card v-bind="feature" align="left" horizontal>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
              illum veniam cum dolores ratione commodi beatae quas maxime,
              laboriosam excepturi solut!
            </base-avatar-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </base-section>
</template>

<script>
import { EventBus } from '../../event-bus';
export default {
  name: "SectionAffiliates",
  data: () => ({
    features: [
      {
        title: "Ana Sílvia Gonçalves",
        text: "Developer",
        image: "ana",
      },
      {
        title: "António Martins",
        text: "Developer",
        image: "antonio",
      },
      {
        title: "António Baldé",
        text: "Developer",
        image: "tony",
      },
      {
        title: "Cyrille Feijó",
        text: "Developer",
        image: "cyrille",
      },
      {
        title: "Daniel Baeta",
        text: "Developer",
        image: "daniel",
      },
      {
        title: "David Neves",
        text: "Developer",
        image: "david",
      },
      {
        title: "Filipe Pereira",
        text: "Developer",
        image: "filipe",
      },
      {
        title: "João Santos",
        text: "Developer",
        image: "joao",
      },
      {
        title: "José Marques",
        text: "Developer",
        image: "jose",
      },
      {
        title: "Luís Salvado",
        text: "Developer",
        image: "luis",
      },
      {
        title: "Mário Rui Castro",
        text: "Developer",
        image: "mario",
      },
      {
        title: "Nuno Agapito",
        text: "Developer",
        image: "nuno",
      },
      {
        title: "Telmo Manique",
        text: "Developer",
        image: "telmo",
      },
      {
        title: "Tiago Santos",
        text: "Developer",
        image: "tiago",
      },
    ],
  }),
  methods: {

    handleIntersect(entries, observer) {
      if (entries[0].isIntersecting) {
        EventBus.$emit('changeTab', "#afilliates");
      }
    }
  }
};
</script>
